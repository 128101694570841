import HTTP from "./HTTP";

import {URL_SERVICE, API_VERSION} from '../constants'

export const getTransactionsList = ({userId=0, offset=0,limit= 10, filter=''})=>{ ///
    return HTTP.get(`${URL_SERVICE}${API_VERSION}/users/${userId}/transactions?offset=${offset}&limit=${limit}&filter=${filter}`);
}

export const getTransaction = (userId, transactionId) => {
    return HTTP.get(`${URL_SERVICE}${API_VERSION}/users/${userId}/transactions/${transactionId}`);
}

export const deleteTransaction = (userId,transactionId) => { ///
    return HTTP.delete(`${URL_SERVICE}${API_VERSION}/users/${userId}/transactions/${transactionId}`);
}

export const updateTransaction = (userId, transactionId, data) => {
    return HTTP.put(`${URL_SERVICE}${API_VERSION}/users/${userId}/transactions/${transactionId}`, data);
}

export const createTransaction = (userId,data) => { ///
    return HTTP.post(`${URL_SERVICE}${API_VERSION}/users/${userId}/transactions`, data);
}