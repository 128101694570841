import { createSlice } from '@reduxjs/toolkit'

import {
    getUserList,
    getUser,
    updateUser,
    createUser,
    createAdminUser,
    getAdminList,
    getAdminUser,
    updateAdminUser
} from '../api/users'

const initialState = {
    userList: {
        results: null,
        limit:0,
        skip:0,
        recordsTotal:0,
    },
    adminList: {
        results: null,
        limit:0,
        skip:0,
        recordsTotal:0,
    },
}


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        fillUserList: (state,{payload}) => {
            state.userList = payload;
        },
        fillAdminList: (state,{payload}) => {
            state.userList = payload;
        },
    },
});

export const { fillUserList, fillAdminList } = userSlice.actions;

export const getUserListFromService = (options) => async dispatch => {
    try{
        const {data:{data}} = await getUserList(options);
        dispatch(fillUserList(data));
    } catch(e) {
        console.log(e);
        dispatch(fillUserList({
            results:[],
            limit:0,
            skip:0,
            recordsTotal:0,
        },));
    }

}

export const createUserFromService = async (userData) => {
    console.log(userData);
    //separamos la creación de user dependiendo del tipo de usuario que sea
    if(userData.profile === 'ADMIN'){
        return await createAdminUser(userData);
    } else {
        return await createUser(userData);
    }
}

export const getUserFromService = async (userId) => {
    try {
       const {data:{data}} = await getUser(userId);
       return data || [];
    } catch (error) {
        console.log(error);
        return []
    }
}

export const updateUserFromService =  async (userId, data) => {
    try {
        await updateUser(userId, data);
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}

// actions for admins
export const getAdminListFromService = (options) => async dispatch => {
    try{
        const {data:{data}} = await getAdminList(options);
        dispatch(fillAdminList(data));
    } catch(e) {
        console.log(e);
        dispatch(fillAdminList({
            results:[],
            limit:0,
            skip:0,
            recordsTotal:0,
        },));
    }

}

export const getUserAdminFromService = async (userId) => {
    try {
       const {data:{data}} = await getAdminUser(userId);
       return data || [];
    } catch (error) {
        console.log(error);
        return []
    }
}

export const updateAdminUserFromService =  async (userId, data) => {
    try {
        await updateAdminUser(userId, data);
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export default userSlice.reducer;