import {palette} from 'shared/src/styles/palette';
import {theme} from 'shared';
const Styles = {
    TypographyTitle: {textAlign: 'left', fontSize: '1.125rem',fontWeight: '700', mt:2, mb:2},
    List: {
        width: '100%',
        height: '300px',
        overflow: 'auto',
        overFlowY: 'auto',
        backgroundColor: palette.background.default,
    },
    BoxGrid: {
        width:'100%',
        height: '100%',
        padding: 2,
    },
    Avatar : {
        width: '60px',
        height: '60px',
        backgroundColor: palette.gray[80],
        border: `3px solid ${palette.gray[90]}`
    },
    ListItem: {
        margin: 1, bgcolor: palette.common.white
    },
    goCardCustomModal: {width:{xs: '90%',md:'40%'}},
    successColor: palette.success.main,
    Box: {
        padding: 2,
        borderRadius: '5px',
        margin: 1,
        height: '100%',
        width: '100%',
        paddingBottom:'0px',

    },
    Ty1: {color: '#fff', mb: '12px', fontSize: '1rem', fontWeight: 700},
    Ty2: {color: '#fff', mb: '14px', fontSize: '14px', fontWeight: 400},
    gridItem: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: '-12px',
        },
    }
    

}

export default Styles;