import React from "react";
import { Skeleton, Box } from "@mui/material";

export default function UserDataSkeleton() {
    return(
        <Box sx={{width:'200px'}}>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
        </Box>
    );
}