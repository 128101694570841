import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import userSlice from './userSlice';
import notificationSlice from './notificationSlice';
import transactionSlice from './transactionSlice'
import accountSlice from './accountSlice';
import authSlice from './authSlice';
import {AUTH_ACCOUNT_INFO} from '../constants'

const persistConfig = {
  key: AUTH_ACCOUNT_INFO,
  storage,
  whitelist: ['authSlice'],
};

const reducers = combineReducers({
  authSlice,
  userSlice,
  notificationSlice,
  transactionSlice,
  accountSlice
});

const persistedReducers = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducers,
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
});

const persistor = persistStore(store);

export  {store, persistor};