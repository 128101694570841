import axios from 'axios';
import { handleExpiredToken, handleServerFatalError, handleToken } from './interceptors';


const HTTP = axios.create();

HTTP.interceptors.request.use(handleToken);
HTTP.interceptors.response.use((request) => request, handleExpiredToken);
// HTTP.interceptors.response.use((request) => request, handleServerFatalError);

export default HTTP;
