import { styled } from '@mui/system';
import { Link } from "react-router-dom";
import theme from 'shared/src/styles/theme'

const StyledLink = styled(Link)({
    width: '100%',
    textDecoration: 'none',
    color: theme.palette.common.black 
});

const Styles = {
    modalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    }
}

export default Styles;
export {StyledLink};