import { createTheme } from '@mui/material/styles';
const { paletteFunctions } = createTheme();

export const palette = {
    common: {
        black: '#000',
        white: '#fff',
    },
    primary: {
        main: '#164F73'
    },
    background: {
        default: '#f6f6f6'
    },
    error: {
        main: '#ef5350',
    },
    success: {
        main: '#4caf50',
    },
    blue: {
        100: '#32A9D9',
        200: '#2182BF',
        300: '#164F73',
    },
    green: {
        100: '#038C7F',
    },
    gray: {
        80: '#f7f7f7',
        90: '#e4e2e2',
        100: '#dcdcdc',
        200: '#5C5C5C'
    }
}