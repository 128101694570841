//import axios from 'axios';
import {logout} from '../store/authSlice';
import {store} from '../store/store';

export const handleToken = async request => {
    const state = store?.getState();
    const authData = state?.authSlice?.account;
    request.headers = request.headers || {};
    if (authData?.token) {
        request.headers['x-access-token'] = authData.token;
    }
    return request;
};

export const handleExpiredToken = async axiosErr => {
    if (axiosErr.message === 'Network Error' && !axiosErr.response) {
        console.error('Error CORS detectado.');
        store.dispatch(logout());
    }
    if (axiosErr?.response?.status === 401 || axiosErr?.response?.status === 403) {
        store.dispatch(logout());
    }
    throw axiosErr;
};
