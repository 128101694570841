import {palette} from 'shared/src/styles/palette'

const Styles = {
    BoxIcon: {
        display: "flex",
        justifyContent:"flex-end",
        margin:2
    },
    column: {
        minWidth: '200px',
        textAlign: 'center',
        fontWeight: 600,
    }
}

export default Styles;