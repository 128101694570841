import React from "react";
import { useSelector } from 'react-redux';
import {Grid, CssBaseline, Box, Link, Typography,   IconButton,Drawer} from '@mui/material';
import {Menu as MenuIcon} from '@mui/icons-material';
import TopBar from "./TopBar";
import SideBar from "./SideBar";
import MainArea from "./MainArea";
import logo from 'shared/src/assets/images/fdic.png'
import AccountActions from './AccountActions';

import Styles from "./styles";

const drawerWidth = 240;
function Layout(){

    const { account } = useSelector(state => state.authSlice);
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };
    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
          <SideBar/>
        </Box>
    );

    return(<><Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CssBaseline />
        <Grid container spacing={0}>
            <Grid item md={3} lg={2}  sx={{display: { xs: 'none', md: 'block' }}}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sx={{bgcolor: '#fff'}}>
                        <Box sx={{ml:{sx:0,sm:4}}}>
                            <Link href="/dashboard">
                                <Box
                                    alt="app logo"
                                    component="img"
                                    sx = {Styles.logo}
                                    src={logo}
                                />
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <SideBar/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={9} lg={10}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px',
                            pr:'20px',
                            bgcolor: '#000e2b',
                        }}>
                            <Box sx={{ mr: 2, ml:1, display: { md: 'none' } }}>
                                <IconButton
                                    onClick={handleDrawerToggle}
                                >
                                    <MenuIcon sx={{color: '#fff'}} fontSize="large" />
                                </IconButton>
                            </Box>
                            <Box sx={{ml:{sx:0,sm:4}}}>
                            <Link href="/dashboard">
                                <Box
                                    alt="app logo"
                                    component="img"
                                    sx = {{...Styles.logo, display: {md: 'none'}}}
                                    src={logo}
                                />
                                </Link>
                            </Box>
                            <Typography variant="subtitle1" sx={Styles.subtitle} >
                                Hola ! {account.username}
                            </Typography>
                            <AccountActions/>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <MainArea/>
                    </Grid>
                </Grid>
                
            </Grid>
        </Grid>
    </Box>
    <Drawer
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: 'block', md: 'none' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
      }}
    >
      {drawer}
    </Drawer>
    </>);
}

export default Layout;
