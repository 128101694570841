import HTTP from "./HTTP";

import {URL_SERVICE, API_VERSION} from '../constants'

export const getNotificationsList = ({userId, offset, limit, filter = ""})=>{ ///
    return HTTP.get(`${URL_SERVICE}${API_VERSION}/users/${userId}/notifications?offset=${offset}&limit=${limit}&filter=${filter}`);
}

export const getNotification = (userId, notyId) => { ///
    return HTTP.get(`${URL_SERVICE}${API_VERSION}/users/${userId}/notifications/${notyId}`);
}

export const deleteNotification = (userId,notyId) => { ///
    return HTTP.delete(`${URL_SERVICE}${API_VERSION}/users/${userId}/notifications/${notyId}`);
}

export const createNotification = (userId,data) => { ///
    return HTTP.post(`${URL_SERVICE}${API_VERSION}/users/${userId}/notifications`,data);
}

export const UpdateNotificationStatus = (userId,notyId,status) => { ///
    return HTTP.put(`${URL_SERVICE}${API_VERSION}/users/${userId}/notifications/${notyId}/status`,{status});
}



// for Notification templates

export const getNotificationTemplatesList = ({ offset, limit, filter}) => { ///
    return HTTP.get(`${URL_SERVICE}${API_VERSION}/notifications???offset=${offset}&limit=${limit}&filter=${filter}`);
}

export const createNotificationTemplates = (data) => { ///
    return HTTP.post(`${URL_SERVICE}${API_VERSION}/notifications`, data);
}

export const deleteNotificationTemplate = (templateId) => { ///
    return HTTP.delete(`${URL_SERVICE}${API_VERSION}/notifications/${templateId}`);
}

export const getNotificationTemplate = (templateId) => { ///
    return HTTP.get(`${URL_SERVICE}${API_VERSION}/notifications/${templateId}`);
}

export const updateNotificationTemplate = (templateId, data) => { ///
    return HTTP.put(`${URL_SERVICE}${API_VERSION}/notifications/${templateId}`,data);
}
