import React from 'react';
import { Provider } from 'react-redux';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { PersistGate } from 'redux-persist/integration/react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/es';

import { store, persistor, theme } from 'shared'

import publicRoutes from './config/routes/public';
import privateRoutes from './config/routes/private';

function App() {

  const router = createBrowserRouter([...publicRoutes, ...privateRoutes ]);

  return (
    <div>
      <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='es'>
            <CssBaseline />
            <RouterProvider router={router} />
          </LocalizationProvider>
        </ThemeProvider>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;