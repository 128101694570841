export function cutText(string, amount=50) {
    return `${string.substring(0,amount)}...`;
}


export function debounce(func, delay) {
    let timeoutId;
    return function(...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
        func.apply(this, args);
        }, delay);
    }
}

export function getRegexForWildCards(){
    return /\[\w+\]/g; //verifica [palabra]
}

export function existWildcard (string) {
    return getRegexForWildCards().test(string);
}

export function isEditorEmpty(string) {
    return string.replace(/<(.|\n)*?>/g, '').trim().length === 0? true : false;
}

export function wildCardMaper(userData = null) {
    const map = {};

    // Aquí mapeamos las variables
    // Variables que podemos tomar de los datos del cliente.
    map['[nombreCliente]'] = userData? userData.username : '[nombreCliente]';
    //variables que debe ingresar el usuario al momento de crear la notificacion.  (ejemplo de como se usa)
    map['[Fecha]'] = '[Fecha]';
    map['[Saldo]'] = '[Saldo]';

    return map;
}

export  function formatCurrency( number ) {
    if(!number && number !==0){
        return '--'
    }
    return `$ ${number.toLocaleString('es-ES', {
        useGrouping: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }) || ''} USD`; 
}

export function formatDID(userInfo) {
    if(!userInfo){
        return '--';
    }
    return userInfo.DID? `${userInfo?.typeDID} ${userInfo.DID}`: "--"
}