import { createSlice } from '@reduxjs/toolkit';
import {
    getNotificationsList,
    getNotification,
    deleteNotification,
    createNotification,
    getNotificationTemplatesList,
    createNotificationTemplates,
    deleteNotificationTemplate,
    getNotificationTemplate,
    updateNotificationTemplate,
    UpdateNotificationStatus,
} from '../api/notifications';

const defaultDataForNotificationList = {
    filter: "",
    order:[],
    recordsFiltered: 0,
    recordsTotal: 0,
    results: null,
}

const initialState = {
    notificationList: defaultDataForNotificationList,
    notificationTemplateList: defaultDataForNotificationList,
}


export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        fillNotificationList: (state,{payload}) => {
            state.notificationList = payload;
        },
        fillNotificationTemplateList: (state,{payload}) => {
            state.notificationTemplateList = payload;
        },
    },
});

export const { fillNotificationList, fillNotificationTemplateList } = notificationSlice.actions;

export const getNotificationListFromService = ({userId, offset, limit, filter}) => async dispatch => {
    try{
        const {data: {data}} = await getNotificationsList({userId, offset, limit, filter});
        dispatch(fillNotificationList(data));
    } catch(e) {
        console.log(e);
        dispatch(fillNotificationList({...defaultDataForNotificationList, results:[]}));
    }

}

export const getNotificationFromService = async (userId, notyId) => {
    try {
        const {data:{data}} = await getNotification(userId, notyId);
        return data
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const deleteNotificationFromService = async (userId, notyId) => {
    try {
        const {data:{data}} = await deleteNotification(userId, notyId);
        return data;
    } catch (error) {
        console.log(error);
        return false;
    }

}

export const UpdateNotificationStatusFromService = async (userId, notyId, status) => {
    try {
        const {data:{data}} = await UpdateNotificationStatus(userId, notyId, status);
        return data;
    } catch (error) {
        console.log(error);
        return false;
    }

}

export const createNotificationFromService = async (userId, dataNoty) => {
    try {
        const { data } = await createNotification(userId, dataNoty);
        return data
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const getNotificationTemplatesListFromService = (dataOptions) => async (dispatch) => {
    try{
        const {data:{data}} = await getNotificationTemplatesList(dataOptions);
        dispatch(fillNotificationTemplateList(data)); // TODO: change this when api ready
    } catch(e) {
        console.log(e);
        dispatch(fillNotificationTemplateList({...defaultDataForNotificationList, results:[]}));
    }

}

export const deleteNotificationTemplateFromService = async (templateId) => {
    try {
        const {data} = await deleteNotificationTemplate(templateId);
        return data;
    } catch (error) {
        console.log(error);
        return false;
    }

}

export const getNotificationTemplateFromService = async (templateId) => {
    try {
        const {data:{data}} = await getNotificationTemplate(templateId);
        
        return data
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const updateNotificationTemplateFromService =  async (templateId, dataTemplate) => {
    try {
        const {data} = await updateNotificationTemplate(templateId, dataTemplate);
        return data;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export default notificationSlice.reducer;
