import { createSlice } from '@reduxjs/toolkit'

import { getTransactionsList, deleteTransaction,createTransaction } from '../api/transactions';

const defaultDataForTransactionList = {
    filter: "",
    order:[],
    recordsFiltered: 0,
    recordsTotal: 0,
    results: null,
}

const initialState = {
    transactionList: defaultDataForTransactionList
}


export const transactionSlice = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
        fillTransactionList: (state,{payload}) => {
            state.transactionList = payload;
        },
    },
});

export const {fillTransactionList} = transactionSlice.actions;

export const getTransactionsListFromService = (transactionParameters) => async dispatch => {
    try{
        const {data:{data}} = await getTransactionsList(transactionParameters);
        dispatch(fillTransactionList(data));
    } catch(e) {
        console.log(e);
        dispatch(fillTransactionList({...defaultDataForTransactionList, results:[]}));
    }

}

export const deleteTransactionFromService = async (userId,transactionId) => {
    try {
        const {data} = await deleteTransaction(userId,transactionId);
        return data;
    } catch (error) {
        console.log(error);
        return false;
    }

}

export const createTransactionFromService = async (userId, dataTransaction) => {
    try {
        const { data } = await createTransaction(userId, dataTransaction);
        return data
    } catch (error) {
        console.log(error);
        return false;
    }
};

export default transactionSlice.reducer;

