import {palette} from 'shared/src/styles/palette'

const Styles = {
    editIconButton: {
        margin:3,
    },
    gridAvatar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    avatar: {
        width: 60,
        height: 60,
        margin:2
    },
    userInfo:{
        fontWeight: 700
    },
    stackAllUserInformation: {margin:2,padding:2, flexDirection: {xs:'column', md:'row'}, border: '1px solid #ebe9e9'}

}

export default Styles;

