import {palette}  from 'shared/src/styles/palette';
import { theme } from 'shared'

const styles = {
    toolBar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    appBar: {
        height: "80px",
        justifyContent: "center",
        backgroundColor: palette.common.white,
    },
    logo: {
        mt: '10px',
        width: '250px',
        [theme.breakpoints.down('md')]: {
            width: '200px',
        },
    },
    subtitle: {
        color:  palette.common.black,
        mr:4,
        display: { xs: 'none', sm: 'block' }
    }
}

export default styles;