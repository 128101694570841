import HTTP from "./HTTP";

import {URL_SERVICE, API_VERSION} from '../constants'

export const listGoCards = ({offset=0, limit=100, filter="all" }) => {
    return HTTP.get(`${URL_SERVICE}${API_VERSION}/cardsGo?offset=${offset}&limit=${limit}&filter=${filter}`);
}

export const requestGoCard = (data) => {
    return HTTP.post(`${URL_SERVICE}${API_VERSION}/cardsGo`, data);
}

export const deleteGoCard = (goCardId) => {
    return HTTP.delete(`${URL_SERVICE}${API_VERSION}/cardsGo/${goCardId}`);
}

export const updateGoCard = (goCardId, data) => {
    return HTTP.put(`${URL_SERVICE}${API_VERSION}/cardsGo/${goCardId}`, data);
}

export const getGoCard = (goCardId) => {
    return HTTP.get(`${URL_SERVICE}${API_VERSION}/cardsGo/${goCardId}`);
}

