import React from "react";
import {
    Stack,
    Typography,
    Paper,
} from '@mui/material';
import TransactionsTable from "../TransactionsTable";

export default function TransactionsView() {
    return(
        <Stack>
            <Typography variant="h1" color="primary" sx={{textAlign: 'left', fontSize: '1.125rem',fontWeight: '700', mt:2, mb:2}}>Listado de Transacciones</Typography>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TransactionsTable/>
            </Paper>
        </Stack>    
    );
}