import React from "react";
import {Modal, Box, Container, Snackbar, Alert} from '@mui/material'
import Editor from './Editor';
import Styles, { StyledLink } from "./styles";

function CustomRouterLink(props) {
    return(
        <StyledLink {...props}>
            {props.children}
        </StyledLink>
    );
};

function CustomModal({handleClose, open, customStyles, children}){
    return (<Modal
            open={open}
            onClose={handleClose}
            disableEscapeKeyDown
            aria-labelledby=""
            aria-describedby=""
        >
            <Box sx={{...Styles.modalBox, ...customStyles}}>
                <Container>
                    {children} 
                </Container>
            </Box>
        </Modal>
    );
}

function CustomSnackbar({opeSnackbar, messageForAlert, handleCloseAlert, position= {vertical:'top', horizontal:'right' }}){
    return (
        <Snackbar open={opeSnackbar} autoHideDuration={5000}  onClose={handleCloseAlert} anchorOrigin={position}>
            <Alert severity={messageForAlert.severity}>
                {messageForAlert.message}
            </Alert>
        </Snackbar>
    );
}

export { CustomRouterLink, CustomModal, CustomSnackbar, Editor };