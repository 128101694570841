import React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Person2Icon from '@mui/icons-material/Person2';
import LoginIcon from '@mui/icons-material/Login';
// import CreditCardIcon from '@mui/icons-material/CreditCard';
// import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import SessionChecker from '../../components/SessionChecker';
import Dashboard from '../../components/Dashboard';
import TransactionsView from '../../components/TransactionsView';
import BankAccounts from '../../components/BankAccounts';
import UserProfile from '../../components/UserProfile'
import MenuActions from '../../components/Layout/MenuActions';
// import Users from '../../components/Users';
import Layout from '../../components/Layout'
// import GoCard from '../../components/GoCard'
// import NotificationTemplates from '../../components/NotificationTemplates';
// import UserDetail from '../../components/Users/UserDetail';
// import Admins from '../../components/Admins';
// import AdminProfile from '../../components/Admins/AdminProfile';

import NotFound from '../../utils/NotFound';

const routesForMenu = [
    {
        path:"dashboard",
        element: <SessionChecker><Dashboard/></SessionChecker>,
        icon:<DashboardIcon color='primary'/>,
        text:"DASHBOARD",
    },
    {
        path:"transactions",
        element: <SessionChecker><TransactionsView/></SessionChecker>,
        icon:<ReceiptLongIcon color='primary'/>,
        text:"TRANSACCIONES",
    },
    {
        path:"bank-accounts",
        element: <SessionChecker><BankAccounts/></SessionChecker>,
        icon:<AccountBalanceIcon color='primary'/>,
        text:"CUENTAS",
    },
    {
        path:"see-profile",
        element: <SessionChecker><MenuActions/></SessionChecker>,
        icon:<Person2Icon color='primary'/>,
        text:"PERFIL",
    },
    {
        path:"close-session",
        element: <SessionChecker><MenuActions/></SessionChecker>,
        icon:<LoginIcon color='primary'/>,
        text:"CERRAR SESIÓN",
    },
]

const privateRoutes = [
    {

        path: '/',
        title: 'FXinfinity',
        exact: true,
        element: <SessionChecker><Layout /></SessionChecker>,
        errorElement: <NotFound/>,
        children: [
            {
                path:"user/:userId/profile",
                title: 'FXinfinity',
                element: <SessionChecker><UserProfile/></SessionChecker>,
            },
            ...routesForMenu
        ],
    }
]


export default privateRoutes;

export {routesForMenu}