import React from 'react';
import Login from '../../components/Login'

const publicRoutes = [
    {
        path: '/login',
        title: 'login',
        exact: true,
        element: <Login />,
    }
]

export default publicRoutes;