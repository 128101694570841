/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import dayjs from 'dayjs';
import { useSelector,useDispatch } from 'react-redux';
import {
    Box,
    Grid,
    IconButton,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    TablePagination,
    CircularProgress,
    Paper,
    Stack,
    Button,
} from '@mui/material'
import {
    AddCircleOutline as AddCircleOutlineIcon,
    Menu as MenuIcon,
} from '@mui/icons-material';

import { ACCOUNT_STATE, BANK_ACCOUT_TYPE } from 'shared/src/constants';

import MenuOptions from './MenuOptions';
import NewBankAcountModal from './NewBankAcountModal';

import { getAccountListFromService } from 'shared/src/store/accountSlice';

import Styles from "./styles";


export default function BankAccounts() {

    const dispatch = useDispatch();
    const { accountList } = useSelector(state=> state.accountSlice);
    const {account} = useSelector(state=> state.authSlice);

    const [open, setOpen] = React.useState(false);
    const [BankAccountId, setBankAccountId] = React.useState(null);
    const [anchorElForMenuOption, setAnchorElForMenuOption] = React.useState(null);
    const [loadingMoreResults, setLoadingMoreResults] = React.useState(true);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const columns = [
        { id: 'accountNumber', label: 'Número de Cuenta', sx: Styles.column  },
        { id: 'bank', label: 'Banco', sx: Styles.column  },
        { id: 'accountType', label: 'Tipo de Cuenta', sx: Styles.column  },
        { id: 'state', label: 'Estado', sx: Styles.column },
        { id: 'options', label: 'Opciones', sx: Styles.column  },
    ];

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClickMenuIcon = (e, BankAccountId) => {
        setBankAccountId(BankAccountId);
        setAnchorElForMenuOption(e.currentTarget);
    };

    const handleOpen = () => setOpen(true);

    const formatRow = (row) => {
        return [
            {label:'accountNumber', value: row.Nro},
            {label:'bank', value: row.bankname},
            {label:'accountType', value: BANK_ACCOUT_TYPE[row.type] || '--'},
            {label:'state', value: ACCOUNT_STATE[row.state] || '--'},
        ]
    }

    const handleCloseForMenuOption = () => {
        setAnchorElForMenuOption(null);
    };

    const getAccountlistFromService = () => {
        setLoadingMoreResults(true);
        dispatch(getAccountListFromService({ userId: account.IDUser, offset: (page * rowsPerPage), limit: rowsPerPage, filter: '' }));
    }

    React.useEffect(()=>{
        if(account !== null){
            getAccountlistFromService();
        }
    }, [page, rowsPerPage, account]);

    React.useEffect(()=> {
        if(accountList.results !== null){
          setLoadingMoreResults(false);
        }
    },[accountList])

    return(<>
        <Stack>
            <Typography variant="h1" color="primary" sx={{textAlign: 'left', fontSize: '1.125rem',fontWeight: '700', mt:2, mb:2}}>Listado de Cuentas Bancarias</Typography>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <Grid container >
                    <Grid item md={12}>
                        <Box sx={Styles.BoxIcon}>
                            <Button onClick={handleOpen} variant="contained" startIcon={<AddCircleOutlineIcon/>} size="small" >
                                Nueva Cuenta Bancaria
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item md={12} sx={{overflowX: 'auto'}}>
                        <TableContainer sx={{ maxHeight: 400 }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                sx={column.sx}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!loadingMoreResults && accountList.results.map((banckAccount) => (
                                        
                                        <TableRow key={banckAccount.IDAccount} >
                                            {
                                                formatRow(banckAccount).map((cellValue) => (
                                                    <TableCell
                                                        key={cellValue.label}
                                                        align="center"
                                                    >
                                                        <Typography>{cellValue.value}</Typography>
                                                    </TableCell>
                                                )
                                                )
                                            }
                                            <TableCell align="center">
                                                <Box>
                                                    <IconButton onClick={(e)=>handleClickMenuIcon(e, banckAccount.IDAccount)}>
                                                        <MenuIcon />
                                                    </IconButton>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {
                                        loadingMoreResults
                                        && <TableRow><TableCell colSpan={7}><Box sx={{display: 'flex', justifyContent: 'center',}}><CircularProgress/></Box></TableCell></TableRow>
                                    }
                                    {
                                        accountList?.results?.length === 0 && !loadingMoreResults
                                        && <TableRow><TableCell colSpan={7}><Box sx={{display: 'flex', justifyContent: 'center',}}>Sin registros</Box></TableCell></TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={accountList?.recordsFiltered || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            showFirstButton
                            showLastButton
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Stack> 
        <Box>
            <NewBankAcountModal getAccountlistFromService = {getAccountlistFromService} open={open} setOpen={setOpen} userId={account.IDUser}/>
            <MenuOptions
                userId={account.IDUser} 
                getAccountlistFromService = {getAccountlistFromService}
                BankAccountId = {BankAccountId}
                anchorElForMenuOption = {anchorElForMenuOption}
                handleCloseForMenuOption = {handleCloseForMenuOption}
            />
        </Box>
    </>)
}