import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import {logout} from 'shared/src/store/authSlice';

export default function MenuActions() {
    const { account } = useSelector(state=> state.authSlice);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const path = location.pathname;

    React.useEffect(()=>{

        if(path === '/close-session') {
            dispatch(logout());
            navigate(0);
        }else{
            if(path === '/see-profile'){
                navigate(`/user/${account?.IDUser}/profile`);
            }else{
                navigate(`dashboard`);
            }
        }
    },[]);


    return(null);
}