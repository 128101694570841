import HTTP from "./HTTP";

import {URL_SERVICE, API_VERSION} from '../constants'

export const getUserList = ({offset=0, limit=100, filter="all" }) => {
    return HTTP.get(`${URL_SERVICE}${API_VERSION}/users?offset=${offset}&limit=${limit}&filter=${filter}`);
}

export const createUser = (data) => {
    return HTTP.post(`${URL_SERVICE}${API_VERSION}/users`, data);
}

export const getUser = (userId) => {
    return HTTP.get(`${URL_SERVICE}${API_VERSION}/users/${userId}`);
}

export const updateUser = (userId, data) => {
    return HTTP.put(`${URL_SERVICE}${API_VERSION}/users/${userId}`,data);
}

export const deleteUser = (userId) => {
    return HTTP.delete(`${URL_SERVICE}${API_VERSION}/users/${userId}`);
}


// Endpoinds For admins

export const getAdminList = ({offset=0, limit=100, filter="all" }) => {
    return HTTP.get(`${URL_SERVICE}${API_VERSION}/admins?offset=${offset}&limit=${limit}&filter=${filter}`);
}

export const getAdminUser = (userId) => {
    return HTTP.get(`${URL_SERVICE}${API_VERSION}/admins/${userId}`);
}

export const updateAdminUser = (userId, data) => {
    return HTTP.put(`${URL_SERVICE}${API_VERSION}/admins/${userId}`,data);
}

export const createAdminUser = (data) => {
    return HTTP.post(`${URL_SERVICE}${API_VERSION}/admins`, data);
}
            