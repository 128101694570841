import HTTP from "./HTTP";

import {URL_SERVICE, API_VERSION} from '../constants'

export const requestTransfer = () => {
    return HTTP.post(`${URL_SERVICE}${API_VERSION}/transfers`, {status: 'IN_PROGRESS'});
}

export const listTransfers = ({offset=0, limit=100, filter="all" }) => {
    return HTTP.get(`${URL_SERVICE}${API_VERSION}/transfers?offset=${offset}&limit=${limit}&filter=${filter}`);
}

export const getTransfer = (transferId) => {
    return HTTP.get(`${URL_SERVICE}${API_VERSION}/cardsGo/${transferId}`);
}

export const deleteTransfer = (transferId) => {
    return HTTP.delete(`${URL_SERVICE}${API_VERSION}/transfers/${transferId}`);
}

export const updateTransfer = (transferId, data) => {
    return HTTP.put(`${URL_SERVICE}${API_VERSION}/transfers/${transferId}`, data);
}