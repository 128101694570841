import React from "react";
import {List} from '@mui/material'
import MenuItem from "./MenuItem";

import { routesForMenu } from "../../../../config/routes/private";

function Menu(){

    return (
        <List>
            {routesForMenu.map((item, index) => (
                <MenuItem key={`new-menu-item-${index}`} item={item} />
            ))}
        </List>
    );
}

export default Menu;