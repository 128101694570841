import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';


function SessionChecker({children}) {
    const {account} = useSelector(state => state.authSlice);


    return (
        <Fragment>
            { account === null ? (
                 <Navigate to="/login" />
            ) : (
                children
            )}
        </Fragment>
    );
}

export default SessionChecker;