import React from 'react';
import { Box, Typography } from '@mui/material';

import styles from './styles';

export default function NotFound() {
  return (
    <Box
      sx={styles}
    >
      <Typography variant="h1" style={styles}>
        404
      </Typography>
    </Box>
  );
}