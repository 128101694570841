/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { useNavigate } from "react-router-dom";
import {
  Link,
  Box,
  Typography,
  Container,
  Avatar,
  Button,
  CssBaseline,
  TextField,
  CircularProgress,
  Stack,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  Grid,
  Paper,
  InputBase,
  Input,
} from '@mui/material';
import { styled } from '@mui/material';
import {LockOutlined as LockOutlinedIcon, Visibility, VisibilityOff} from '@mui/icons-material';

import { CustomSnackbar } from '../SharedComponents';
import TopBar from './TopBar';
import {loginFromService, setAuthError} from 'shared/src/store/authSlice';

import Styles from './styles';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://fxinfinity.net/" target='_blank'>
        fxinfinity.net
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export const StyledInput = styled('input')(Styles.inputs);

export default function Login() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {account, authError, logoutStatus} = useSelector((state)=> state.authSlice)

  const [disableSendButton, setDisableSendButton] = React.useState(false);
  const [openSnackbarError, setOpenSnackbarError] = React.useState(false);
  const [openSnackbarLogout, setOpenSnackbarLogout] = React.useState(false);
  const [passEmpty, setPassEmpty] = React.useState(false);
  const [emailEmpty, setEmailEmpty] = React.useState(false);

  const [messageForAlert, setMessageForAlert] = React.useState({});

  const handleChangeInput = ()=>{
    setEmailEmpty(false);
    setPassEmpty(false);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if(data.get('email')=== ''){
      setEmailEmpty(true);
      return;
    }

    if(data.get('password')=== ''){
      setPassEmpty(true);
      return;
    }
    setDisableSendButton(true);
    dispatch(loginFromService(data.get('email'),data.get('password')));
  };

  // for small notification
  const handleCloseAlert = (e, reason)=> {
    if (reason === 'clickaway') return;
    setOpenSnackbarError(false);
    setOpenSnackbarLogout(false);
  }

  React.useEffect(()=>{
    if(account !== null) {
      navigate('/dashboard');
    }
  },[account]);

  React.useEffect(()=>{
    if(authError !== false){
      if(authError === 404){
        setMessageForAlert({message:"La combinación de usuario y contraseña no es correcta", severity:"error" });
      } else if (authError === 0){
        setMessageForAlert({message:"Solo usuarios Cientes pueden ingresar", severity:"error" });
      } else {
        setMessageForAlert({message:"Ha ocurrido un error interno", severity:"error" });
      }
      setOpenSnackbarError(true);
      setDisableSendButton(false);
      dispatch(setAuthError(false));
    }

  }, [authError]);

  React.useEffect(() => {
    if(logoutStatus === true){
      setMessageForAlert({message:"Se ha cerrado la sesión", severity:"success" });
      setOpenSnackbarLogout(true);
    }
  },[logoutStatus]);

  return (
    <>
      <Container maxWidth="lg">
        <Box sx={{display: 'flex', minHeight: 'calc(100vh - 20px)', alignItems: 'center'}}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={7}>
              <Box sx={{bgcolor: '#000e2b', color: '#fff', minHeight: '100%', padding: '3rem'}}>
                <Typography sx={{fontWeight: '700', fontSize: '1.875rem'}}>Acerca de la FDIC</Typography>
                <Typography sx={Styles.paragraf}>La Corporación Federal de Seguro de Depósitos (FDIC) es una
                  agencia independiente creada por el Congreso para mantener
                  la estabilidad y la confianza pública en el sistema
                  financiero internacional. La FDIC asegura los depósitos
                  y distintas transacciones de los usuarios; examina y
                  supervisa a las instituciones financieras en cuanto a
                  seguridad, solidez y protección
                </Typography>
                <Typography  sx={Styles.paragraf}>El seguro de depósito de la FDIC
                  permite que los consumidores depositen su dinero con confianza a
                  través del sistema financiero internacional. Latinoamérica
                  esta cubierta por la FDIC. Usted está respaldado por la
                  confianza plena y el crédito del gobierno de los Estados Unidos.
                </Typography>
                
              </Box>
            </Grid>
            <Grid item xs={12}  md={5}>
              <Box sx={Styles.container}>
                <Box
                      sx={{
                      
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Typography component="h2" sx={Styles.TypographyLogin}>
                        Iniciar sesión
                      </Typography>
                      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, mr:2, ml:2, width: '90%' }}>
                      <Box sx={{marginBottom:'32px',}}>
                        <StyledInput
                          required
                          id="email"
                          name="email"
                          type='email'
                          autoComplete="email"
                          placeholder="Enter Username"
                          onChange={handleChangeInput}
                        />
                        <Typography sx={{...Styles.errorMessage, display: emailEmpty? 'block': 'none'}}>
                          This is a required field
                        </Typography>
                      </Box>
  
                      <Box>
                        <StyledInput
                          required
                          id="password"
                          name="password"
                          type='password'
                          autoComplete="password"
                          placeholder="Enter Password"
                          onChange={handleChangeInput}
                        />
                        <Typography sx={{...Styles.errorMessage, display: passEmpty? 'block': 'none'}}>
                          This is a required field
                        </Typography>
                      </Box>
                      
                      <Button
                        disabled ={disableSendButton}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, bgcolor: '#000e2b' }}
                      >
                        {!disableSendButton && "Iniciar Sesión"}
                        {disableSendButton && <CircularProgress color="inherit" size={25} />}
                      </Button>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4, display: 'none'}} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <CustomSnackbar opeSnackbar={ openSnackbarError } messageForAlert={messageForAlert} handleCloseAlert={handleCloseAlert}/>
      <CustomSnackbar opeSnackbar={ openSnackbarLogout } messageForAlert={messageForAlert} handleCloseAlert={handleCloseAlert}/>
    </>
  );
}
