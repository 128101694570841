import HTTP from "./HTTP";

import {URL_SERVICE, API_VERSION} from '../constants'

export const getBankAccontList = ({userId=0, offset=0,limit= 10, filter=''})=>{
    return HTTP.get(`${URL_SERVICE}${API_VERSION}/users/${userId}/bankaccount?offset=${offset}&limit=${limit}&filter=${filter}`);
}

export const createBankAccount = (userId,data) => { ///
    return HTTP.post(`${URL_SERVICE}${API_VERSION}/users/${userId}/bankaccount`, data);
}

export const updateBankAccount = (userId, bankAccountId, data) => { ///
    return HTTP.put(`${URL_SERVICE}${API_VERSION}/users/${userId}/bankaccount/${bankAccountId}`, data);
}

export const getBankAccount = (userId, bankAccountId) => {
    return HTTP.get(`${URL_SERVICE}${API_VERSION}/users/${userId}/bankaccount/${bankAccountId}`);
}

export const deleteBankAccount = (userId,bankAccountId) => { ///
    return HTTP.delete(`${URL_SERVICE}${API_VERSION}/users/${userId}/bankaccount/${bankAccountId}`);
}