import { createSlice } from '@reduxjs/toolkit';
import {
    getBankAccontList,
    createBankAccount,
    deleteBankAccount,
} from '../api/accounts';

const initialState = {
    accountList: {
        results: null,
        limit:0,
        skip:0,
        recordsTotal:0,
    },
}


export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        fillAccountList: (state,{payload}) => {
            state.accountList = payload;
        },
    },
});

export const { fillAccountList } = accountSlice.actions;

export const getAccountListFromService = ({userId, offset, limit, filter}) => async dispatch => {
    try{
        const {data:{data}} = await getBankAccontList({userId, offset, limit, filter});
        dispatch(fillAccountList(data));
    } catch(e) {
        console.log(e);
        dispatch(fillAccountList({
            results: [],
            limit:0,
            skip:0,
            recordsTotal:0,
        }));
    }

}

export const createBankAccountFromService = async (userId, dataBankAccount) => {
    try {
        const { data } = await createBankAccount(userId, dataBankAccount);
        return data
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const deleteBankAccountFromService = async (userId,bankAccountId) => {
    try {
        const {data} = await deleteBankAccount(userId,bankAccountId);
        return data;
    } catch (error) {
        console.log(error);
        return false;
    }

}

export default accountSlice.reducer;