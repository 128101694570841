import React from "react";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {Grid, Typography, Button, TextField, Box, CircularProgress, MenuItem} from '@mui/material';
import {CustomModal, CustomSnackbar} from '../SharedComponents';

import { requestGoCard} from 'shared/src/api/goCards'

import Styles from './styles'

const requireErrorMessage = "Este campo es requerido";
const successMessage = "La solicitud se ha creado con éxito";
const errorMessage = "Ha ocurrido un error";

const defaultValuesFordateField = {
    address:   '',
    city:   '',
    state: '',
    phone:   '',
    postalcode: '',
    country: '',
}

export default function GoCardForm({ open, setOpen, userId}) {

    const {account} = useSelector((state)=> state.authSlice)
    const [messageForAlert, setMessageForAlert] = React.useState({});
    const [opeAlert, setOpenAlert] = React.useState(false);
    const [actionButtonDisabled, setActionButtonDisabled] = React.useState(false);

    const handleClose = (_, reason) => {
        if(reason === "backdropClick") return;
        setActionButtonDisabled(false);
        reset({...defaultValuesFordateField, phone: account.phone });
        setOpen(false);
    };

    const { control, handleSubmit, reset } = useForm({
        defaultValues: {...defaultValuesFordateField, phone: account.phone }
    });

    const handleOnSubmit = async (data) =>{
        setActionButtonDisabled(true);
        try {
            await requestGoCard(data);
            setMessageForAlert({message:successMessage,severity:"success" });
        } catch (error) {
            const {response} = error;
            if(response.status === 400) {
                setMessageForAlert({message:(response?.data?.msg || errorMessage),severity:"error" });
            } else {
                setMessageForAlert({message:errorMessage,severity:"error" });
            }
        }
        setOpenAlert(true);
        reset({...defaultValuesFordateField, phone: account.phone });
        handleClose(); // to close modal
    }

    // for small notification
    const handleCloseAlert = (e, reason)=> {
        if (reason === 'clickaway') return;
        setOpenAlert(false);
    }

    return(
        <>
            <CustomModal handleClose={handleClose} open={open} customStyles={Styles.goCardCustomModal}>
                <form onSubmit={handleSubmit(handleOnSubmit)}>
                    <Grid container alignItems="center" justify="center" direction="row" spacing={3}>
                        <Grid item xs={12} md={12}>
                            <Typography variant="h6" sx={{mb:2, textAlign: 'center'}}>
                                Solicitar una Tarjeta Go.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Controller
                                name="address"
                                control={control}
                                rules={{
                                    required: requireErrorMessage,
                                    maxLength: 100,
                                }}
                                render={
                                    ({ field, fieldState: { invalid, error,  }}) =>(
                                        <TextField
                                            fullWidth
                                            label="Dirección Completa *"
                                            error={invalid}
                                            helperText={error?.message}
                                            {...field}
                                            inputRef={field.ref}
                                        />
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="city"
                                control={control}
                                rules={{required: requireErrorMessage, maxLength: 100}}
                                render={
                                    ({ field, fieldState: { invalid, error,  }}) =>(
                                        <TextField
                                            fullWidth
                                            label="Ciudad *"
                                            error={invalid}
                                            helperText={error?.message}
                                            {...field}
                                            inputRef={field.ref}
                                        />
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="state"
                                control={control}
                                rules={{required: requireErrorMessage, maxLength: 100}}
                                render={
                                    ({ field, fieldState: { invalid, error,  }}) =>(
                                        <TextField
                                            fullWidth
                                            label="Estado/Departamento *"
                                            error={invalid}
                                            helperText={error?.message}
                                            {...field}
                                            inputRef={field.ref}
                                        />
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="country"
                                control={control}
                                rules={{required: requireErrorMessage, maxLength: 100}}
                                render={
                                    ({ field, fieldState: { invalid, error,  }}) =>(
                                        <TextField
                                            fullWidth
                                            label="País *"
                                            error={invalid}
                                            helperText={error?.message}
                                            {...field}
                                            inputRef={field.ref}
                                        />
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="postalcode"
                                control={control}
                                rules={
                                    {
                                        required: false,
                                        maxLength: 100,
                                        pattern: { 
                                            value: /^[\d+\s]+$/i,
                                            message: "Ingresa solo números"
                                        }
                                    }
                                }
                                render={
                                    ({ field, fieldState: { invalid, error }}) =>(
                                        <TextField
                                            fullWidth
                                            label="Código Postal *"
                                            error={invalid}
                                            helperText={error?.message}
                                            {...field}
                                            inputRef={field.ref}
                                        />
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="phone"
                                control={control}
                                rules={
                                    {
                                        required: false,
                                        maxLength: 100,
                                        pattern: { 
                                            value: /^[\d+\s]+$/i,
                                            message: "Ingresa solo números y espacios"
                                            }
                                    }
                                }
                                render={
                                    ({ field, fieldState: { invalid, error }}) =>(
                                        <TextField
                                            fullWidth
                                            label="Teléfono *"
                                            error={invalid}
                                            helperText={error?.message}
                                            {...field}
                                            inputRef={field.ref}
                                        />
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs={12}  sx={{display: 'flex', justifyContent: 'center',     flexDirection: {xs:'column', md:'row'}}}>
                            <Button onClick={handleClose} variant="contained" color="error" sx={{mr:1, mb: {xs: 2, md: 0}}} disableElevation>Cancelar</Button>
                            <Box sx={{position: 'relative', display: 'flex', mr:1, flexDirection: {xs:'column', md:'row'} }}>
                                <Button variant="contained" color="primary" type="submit" disabled={actionButtonDisabled} disableElevation>
                                    Solicitar Tarjeta Go
                                </Button>
                                {actionButtonDisabled && (
                                    <CircularProgress
                                    size={24}
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </CustomModal>
            <CustomSnackbar opeSnackbar={ opeAlert } messageForAlert={messageForAlert} handleCloseAlert={handleCloseAlert}/>
        </>
    );
}