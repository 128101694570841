import { createSlice } from '@reduxjs/toolkit';
import {login} from '../api/auth';

import {ALLOWED_PROFILES} from '../constants';

const initialState = {
    account: null,
    authError: false,
    logoutStatus: false,
}


export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        fillAuthAccount: (state, { payload } ) => {
            state.account = payload;
        },
        setAuthError: (state, { payload } ) =>{
            state.authError = payload;
        },
        setLogoutStatus: (state, {payload}) => {
            state.logoutStatus = payload;
        }
    },
});

export const { fillAuthAccount, setAuthError, setLogoutStatus } = authSlice.actions;

export const loginFromService = (email,pass) => async (dispatch) => {
    try {
        const {data:{data}} = await login(email,pass);

        // verificamos si el usario tiene permitido acceder al sistema
        const allowed = ALLOWED_PROFILES.find(profile => profile === data.profile);
        if(!allowed) throw "User not allowed";
        dispatch(fillAuthAccount(data));
    } catch (error) {
        const code = error?.response?.data?.code ?? 0;
        dispatch(setAuthError(code));
        dispatch(fillAuthAccount(null));
        console.log(error);
    }
}

export const logout = () => (dispatch)=> {
    console.log('logout');
    dispatch(setLogoutStatus(true));
    dispatch(fillAuthAccount(null));
}

export default authSlice.reducer;