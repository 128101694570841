/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import {useSelector, useDispatch} from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import Swal from 'sweetalert2/src/sweetalert2.js'
import {
    Grid,
    Stack,
    Typography,
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Skeleton,
    CircularProgress,
    Button,
    IconButton,
    Alert,
    Collapse,
} from '@mui/material';
import {
    Notifications as NotificationsIcon,
    Tour as Success, //success
    Warning as WarningIcon, //warning
    Info as InfoIcon, //Info
    ReportGmailerrorred as ErrorIcon,
    Visibility as VisibilityIcon,
} from '@mui/icons-material';
import {getUserFromService} from 'shared/src/store/userSlice';
import  {getNotificationListFromService, getNotificationFromService, UpdateNotificationStatusFromService} from 'shared/src/store/notificationSlice';
import {REASON_MAP} from 'shared/src/constants';
import { formatCurrency, debounce } from 'shared/src/utils';
import {requestTransfer} from 'shared/src/api/transfers';
import { CustomSnackbar } from '../SharedComponents';
import TransactionsTable from '../TransactionsTable';
import GoCardForm from './GoCardForm';

import '@sweetalert2/theme-minimal/minimal.scss';
import 'animate.css';
import Styles from './styles';

const mapTypeToIcon = {
    success: <Success color="success" fontSize="large"/>,
    warning: <WarningIcon color="warning" fontSize="large"/>,
    info: <InfoIcon color="info" fontSize="large"/>,
    error: <ErrorIcon color="error" fontSize="large"/>,
}

const successMessage = "La solicitud se ha creado con éxito";
const errorMessage = "Ha ocurrido un error";

export default function Dashboard() {

    const dispatch = useDispatch();
    const {account} = useSelector(state=> state.authSlice);
    const {notificationList} = useSelector(state => state.notificationSlice);
    const [dataUser, setDataUser] = React.useState(null);
    const [loadingMoreResults, setLoadingMoreResults] = React.useState(true);
    const [openGoCardForm, setOpenGoCardForm] = React.useState(false);
    const [requestingTransaction, setRequestingTransaction] = React.useState(false);
    const [messageForAlert, setMessageForAlert] = React.useState({});
    const [opeAlert, setOpenAlert] = React.useState(false);
    const [openAletForRequestTransaction, SetOpenAletForRequestTransaction] = React.useState(false);
    const [disableSeeNotification, setDisableSeeNotification] = React.useState(false);

    const matchesSmallDevices = useMediaQuery((theme) => theme.breakpoints.down('md'));


    const handleSeeNotificationDetail = async (_,notiId) => {
        setDisableSeeNotification(true);
        const data = await getNotificationFromService(account.IDUser, notiId);
        setDisableSeeNotification(false);
        firePreviewNotificationSwal(data);
    }

    const handleMarkAsRead = async (IDNoty) => {
        await UpdateNotificationStatusFromService(account.IDUser, IDNoty, "VISTA");
        getNotificationList();

    }

    // for small notification
    const handleCloseAlert = (e, reason)=> {
        if (reason === 'clickaway') return;
        setOpenAlert(false);
    }

    const handleRequestTransfer = async (_) => {
        setRequestingTransaction(true);
        try {
            const {status, data} = await requestTransfer();
            if(status === 200){
                if(data?.data){
                    fireBasicSweetAlert(data.data);
                } else {
                    setMessageForAlert({message:successMessage,severity:"success" });
                    setOpenAlert(true);
                }
            }else{
                setMessageForAlert({message: errorMessage,severity:"error" });
                setOpenAlert(true);
            }
        } catch (error) {
            const {response} = error;
            if(response.status === 400) {

                if(response?.data?.data){
                    fireBasicSweetAlert(response.data.data);
                }else{
                    setMessageForAlert({message:(response?.data?.msg || errorMessage),severity:"error" });
                    SetOpenAletForRequestTransaction(true);
                    debounce(()=>SetOpenAletForRequestTransaction(false), 8000)();
                }
            } else {
                setMessageForAlert({message: errorMessage,severity:"error" });
            }
        }

        setRequestingTransaction(false);
    }

    const getUserInformation = async () => {
        const userInformation = await getUserFromService(account.IDUser);
        setDataUser(userInformation);
    }

    const getNotificationList = ()=>{
        if(account !== null){
             dispatch(getNotificationListFromService({userId: account.IDUser, offset: 0, limit: 100, filter: 'EN_COLA'}));
        }
    }

    const fireBasicSweetAlert = (infoNoty)=>{
        Swal.fire({
            icon: infoNoty.type,
            title: infoNoty.title,
            html: infoNoty.body?? infoNoty.message,
            showClass: {
                popup: 'animate__fadeIn'
            },
            hideClass: {
                popup: 'animate__fadeOut'
            }
        })
    }

    const firePreviewNotificationSwal = (infoNoty) => {
        //
        if(!infoNoty) {
            fireBasicSweetAlert({type:'warning', body: 'No se ha podido Encontrar la notificación, intente nuevamente. '});
        } else {
            Swal.fire({
                icon: infoNoty.type,
                title: infoNoty.title,
                html: infoNoty.body,
                showCancelButton: true,
                showConfirmButton: (infoNoty.destiny !== 'SOLICITAR_TRANSACCION'),
                confirmButtonColor: Styles.successColor,
                confirmButtonText: 'Marcar Como leída',
                cancelButtonText: 'Cerrar',
                showClass: {
                    popup: 'animate__fadeIn'
                },
                hideClass: {
                    popup: 'animate__fadeOut'
                }
            }).then(async(result) => {
                if (result.isConfirmed) {
                    await handleMarkAsRead(infoNoty.IDNoty)
                } 
            })
        }
        
    }

    React.useEffect(() => {
        getUserInformation();
        getNotificationList();
    }, [account]);

    React.useEffect(()=> {
        if(notificationList.results !== null){
          setLoadingMoreResults(false);
        }
    },[notificationList])

    return(<>
        <Grid container spacing={2}>
            <Grid item md={12}>
                <Typography variant="h1" color="primary" sx={Styles.TypographyTitle}>Dashboard de clientes</Typography>
            </Grid>
            {/*Balance */}
            <Grid item md={4} xs={12}  sx={{bgcolor: '#fff'}}>
                <Box sx={Styles.BoxGrid}>
                    <Stack>
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                            Balance
                        </Typography>
                        {dataUser === null && <Skeleton variant="rectangular" width={130} height={30} />}
                        {dataUser  !== null && <Typography component="p" variant="h4" sx={{fontSize: '2.25rem', fontWeight: 700, color: '#3E5569'}}>
                            {dataUser?.balance ? formatCurrency(dataUser.balance) : "--"}
                        </Typography>}
                        <Box sx={{maxWidth: 'fit-content'}}>
                            <Box onClick={()=>setOpenGoCardForm(true)} sx={{mt:2,}}>
                                <Button sx={{ bgcolor: '#000E2B', width: '100%' }} variant="contained" size="large"  disabled={dataUser === null} disableElevation>Solicitar Tarjeta Go</Button>
                            </Box>
                            <Box sx={{ position: 'relative', mt:2,  }}>
                                    <Button
                                        onClick={handleRequestTransfer}
                                        sx={{ bgcolor: '#000E2B', }}
                                        variant="contained"
                                        disabled={dataUser === null || requestingTransaction}
                                        disableElevation
                                        size="large"
                                    >
                                        Solicitar Transferencia
                                    </Button>
                                    {requestingTransaction && (
                                        <CircularProgress
                                        size={24}
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                        />
                                    )}
                            </Box>
                        </Box>
                        <Collapse in={openAletForRequestTransaction}>
                            <Alert severity="warning" sx={{mt:2}}>
                                {messageForAlert.message}
                            </Alert>
                        </Collapse>
                    </Stack>
                </Box>
            </Grid>
            {/*info */}
            <Grid item xs={12} md={8}  sx={{bgcolor: '#fff'}}>
                <Grid container spacing={1} sx={{padding: '15px'}}>
                    <Grid item xs={12} md={6} sx={Styles.gridItem}>
                        <Box sx={{...Styles.Box, bgcolor: '#0051FF',}}>
                            <Typography color="primary" sx={Styles.Ty1}>Bufete de Abogados.</Typography>
                            <Typography color="primary" sx={Styles.Ty2} >La FDIC proporciona integración directa con bufete de abogados experimentados
                             para ayudar a los consumidores a tomar decisiones, recuperar sus capitales y proteger sus activos.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={Styles.gridItem}>
                        <Box sx={{...Styles.Box, bgcolor: '#000E2B',}}>
                            <Typography color="primary" sx={Styles.Ty1}>Póliza Integral</Typography>
                            <Typography color="primary" sx={Styles.Ty2} >La FDIC protege a todos los consumidores Latinoaméricanos 
                                con una póliza integral que le garantizará el retorno del capital asegurado en caso de inversiones 
                                fallidas en los mercados financieros a través de distintas plataformas.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={Styles.gridItem}>
                        <Box sx={{...Styles.Box, bgcolor: '#000E2B',}}>
                            <Typography color="primary" sx={Styles.Ty1}>Seguro de Depósitos</Typography>
                            <Typography color="primary" sx={Styles.Ty2} >El Seguro de Depósitos de la FDIC es un mecanismo de 
                                contingencia financiera para la ciudadanía, cuyo objetivo es pagar los depósitos realizados por 
                                los clientes a distintas entidades financieras cubiertas por el Seguro, en el caso de liquidación 
                                forzosa o fraude de cualquiera de ellas, en los términos que la Ley señala.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={Styles.gridItem}>
                        <Box sx={{...Styles.Box, bgcolor: '#0051FF'}}>
                            <Typography color="primary" sx={Styles.Ty1}>Comodo Deducible</Typography>
                            <Typography color="primary" sx={Styles.Ty2} >Realice el pago de su deducible y obtenga el derecho a recuperar lo 
                                que le pertenece. El deducible de un seguro es el dinero que la compañía de seguros no indemniza 
                                en caso de un acontecimiento de cualquier indole. En otras palabras, es el valor que te corresponde 
                                pagar a ti frente a un suceso. De esta forma, la responsabilidad se divide entre la aseguradora y 
                                el asegurado.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            {/*Table */}
            <Grid item xs={12} sx={{ bgcolor: '#fff', mt:2}}>
                <Box sx={Styles.BoxGrid}>
                    <Stack>
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                            Transacciones
                        </Typography>
                        <TransactionsTable/>
                    </Stack> 
                </Box>
                
            </Grid>
        </Grid>
        <GoCardForm  open={openGoCardForm} setOpen={setOpenGoCardForm}/>
        <CustomSnackbar opeSnackbar={ opeAlert } messageForAlert={messageForAlert} handleCloseAlert={handleCloseAlert}/>
    </>);
}