import React from "react";
import { useForm, Controller } from "react-hook-form";
import {Grid, Typography, Button, TextField, Box, CircularProgress, MenuItem} from '@mui/material';
import {CustomModal, CustomSnackbar} from '../SharedComponents';

import { createBankAccountFromService } from 'shared/src/store/accountSlice'

import { BANK_ACCOUT_TYPE } from 'shared/src/constants';

const requireErrorMessage = "Este campo es requerido";
const successMessage = "La cuenta Bancaria se ha añadido con éxito"
const errorMessage = "Ha ocurrido un error";

const defaultValuesFordateField = {
    nro:   '',
    type:   '',
    bankname: '',
    username:   '',
}

export default function NewBankAcountModal({ getAccountlistFromService, open, setOpen, userId}) {

    const [messageForAlert, setMessageForAlert] = React.useState({});
    const [opeAlert, setOpenAlert] = React.useState(false);
    const [actionButtonDisabled, setActionButtonDisabled] = React.useState(false);

    const handleClose = (_, reason) => {
        if(reason === "backdropClick") return;
        setActionButtonDisabled(false);
        reset(defaultValuesFordateField);
        setOpen(false);
    };

    const { control, handleSubmit, reset } = useForm({
        defaultValues: defaultValuesFordateField
    });

    const handleOnSubmit = async data =>{
        console.log(data);
        setActionButtonDisabled(true);
        const result = await createBankAccountFromService(userId, data);

        if(result) {
            setMessageForAlert({message:successMessage,severity:"success" });
            
        }else {
            setMessageForAlert({message:errorMessage,severity:"error" });
        }
        setOpenAlert(true);
        getAccountlistFromService();
        reset(defaultValuesFordateField);
        handleClose(); // to close modal
    }

    // for small notification
    const handleCloseAlert = (e, reason)=> {
        if (reason === 'clickaway') return;
        setOpenAlert(false);
    }

    return(
        <>
            <CustomModal handleClose={handleClose} open={open} customStyles={{width:{xs: '90%',md:'50%'}}}>
                <form onSubmit={handleSubmit(handleOnSubmit)}>
                    <Grid container alignItems="center" justify="center" direction="row" spacing={3}>
                        <Grid item md={12}>
                            <Typography variant="h6" sx={{mb:2, textAlign: 'center'}}>
                                Registrar nueva cuenta Bancaria
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Controller
                                name="nro"
                                control={control}
                                rules={{
                                    required: requireErrorMessage,
                                    maxLength: 100,
                                    pattern: { 
                                        value: /^[0-9]+$/,
                                        message: "Este campo solo acepta números"
                                    }
                                }}
                                render={
                                    ({ field, fieldState: { invalid, error,  }}) =>(
                                        <TextField
                                            fullWidth
                                            label="Número de la cuenta *"
                                            error={invalid}
                                            helperText={error?.message}
                                            {...field}
                                            inputRef={field.ref}
                                        />
                                    )
                                }
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Controller
                                name="type"
                                control={control}
                                rules={{ required: requireErrorMessage,}}
                                render={
                                    ({ field, fieldState: { invalid, error,  }}) =>(
                                    <TextField
                                        fullWidth
                                        select
                                        label="Tipo *"
                                        error={invalid}
                                        helperText={error?.message}
                                        {...field}
                                        inputRef={field.ref}
                                    >
                                        <MenuItem key="" value="">
                                            --
                                        </MenuItem>
                                        {Object.entries(BANK_ACCOUT_TYPE).map(([accConst, accLabel]) => <MenuItem key={accConst} value={accConst}>{accLabel}</MenuItem>)}
                                    </TextField>)
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="bankname"
                                control={control}
                                rules={{required: requireErrorMessage, maxLength: 100}}
                                render={
                                    ({ field, fieldState: { invalid, error,  }}) =>(
                                        <TextField
                                            fullWidth
                                            label="Nombre del Banco *"
                                            error={invalid}
                                            helperText={error?.message}
                                            {...field}
                                            inputRef={field.ref}
                                        />
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="username"
                                control={control}
                                rules={{required: requireErrorMessage, maxLength: 100}}
                                render={
                                    ({ field, fieldState: { invalid, error,  }}) =>(
                                        <TextField
                                            fullWidth
                                            label="Nombre del titular *"
                                            error={invalid}
                                            helperText={error?.message}
                                            {...field}
                                            inputRef={field.ref}
                                        />
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center',flexDirection: {xs:'column', md:'row'}}}>
                            <Button onClick={handleClose} variant="contained" color="error" sx={{mr:1, mb: {xs: 2, md: 0}}} disableElevation >Cancelar</Button>
                            <Box sx={{position: 'relative', display: 'flex', mr:1, flexDirection: {xs:'column', md:'row'} }}>
                                <Button variant="contained" color="primary" type="submit" disabled={actionButtonDisabled}>
                                    Registrar Cuenta bancaria
                                </Button>
                                {actionButtonDisabled && (
                                    <CircularProgress
                                    size={24}
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </CustomModal>
            <CustomSnackbar opeSnackbar={ opeAlert } messageForAlert={messageForAlert} handleCloseAlert={handleCloseAlert}/>
        </>
    );
}