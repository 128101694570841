import { ENV_ALLOWED_PROFILES } from "../env";
export const URL_SERVICE = "https://api.fdic-capitals.com";
export const API_VERSION = "/v1";

export const DATE_FORMAT = "DD/MM/YYYY";
export const DATE_TIME_FORMAT = "DD/MM/YYYY hh:mm:ss";

export const REASON_MAP = {
  SOLICITAR_TRANSACCION: "Solicitar Transacción",
  RECARGAR_TARJETA_GO: "Recargar Tarjeta GO",
};

export const TYPE_NOTIFICATIONS_MAP = {
  success: "success",
  warning: "warning",
  info: "info",
  error: "error",
};

export const USER_STATUS = {
  ACTIVE: "Activo",
  INACTIVE: "Inactivo",
};

export const USER_PROFILE = [
  { type: "CLIENT", label: "Cliente" },
  { type: "ADMIN", label: "Administrador" },
  // {type:'MOD', label: 'Moderador'},
];

export const BANK_ACCOUT_TYPE = {
  AHORRO: "Ahorros",
  CORRIENTE: "Corriente",
};

export const TRANSACTIONS_STATUS = {
  IN_PROGRESS: "En Progreso",
  COMPLETE: "Completa",
  CANCEL: "Cancelada",
};
export const GO_CARD_STATUS = {
  PROCCESED: "Procesada",
};

export const NOTY_STATE = {
  VISTA: "Vista",
  EN_COLA: "En cola",
  ELIMINADA: "Eliminada",
};

export const ACCOUNT_STATE = {
  PENDING: "Pendiente",
  AUTORIZE: "Autorizada",
  CANCEL: "Cancelada",
};

export const TRANSFER_STATUS = {
  IN_PROGRESS: "En progreso",
  COMPLETE: "Completa",
  CANCEL: "Cancelada",
};

export const AUTH_ACCOUNT_INFO = "AuthAccountInfo";
export const AUTH_TOKEN = "AuthToken";

export const ALLOWED_PROFILES = ENV_ALLOWED_PROFILES;

export const DID_TYPES = [
  { type: "CC", label: "Cédula de Ciudadanía (CC)" },
  { type: "NIT", label: "NIT" },
  { type: "CE", label: "Cédula de Extranjería (CE)" },
  { type: "PEP", label: "Permiso Especial de permanencia (PEP)" },
];
