import ReactQuill from 'react-quill';
import { styled } from "@mui/material/styles";

const Styles = {}

const EditorContainer = styled(ReactQuill)({
    '& .ql-editor': {
        height: '135px',
        maxHeight: '135px',
        overflow: 'auto',
    }
});


export default Styles;

export {EditorContainer};

