import {palette} from 'shared/src/styles/palette';
const Styles = {
    Box: {
        height:'100vh'
    },
    Paper: {
        borderRadius: '0px',
        borderRight: `1px solid ${palette.gray[200]}31`,
        
    }
}

export default Styles