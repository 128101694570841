import {theme} from 'shared';
import { palette } from 'shared/src/styles/palette';

const Styles = {
    GridManiArea:{
        backgroundColor:'',
        display: {xs: 'none',sm: 'none',md:'block'},
        minWidth: '190px',
    },
    logo: {
        mt: '10px',
        width: '120px',
        [theme.breakpoints.up('md')]: {
            width: '180px',
        },
    },
    subtitle: {
        color:  palette.common.white,
        mr:4,
        display: { xs: 'none', sm: 'block' },
        marginLeft: 'auto',
    }
}

export default Styles;