import {palette}  from 'shared/src/styles/palette';
import { theme } from 'shared'

const styles = {
    toolBar: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    appBar: {
        height: "80px",
        justifyContent: "center",
        backgroundColor: palette.common.white,
    },
    logo: {
        mt: '10px',
        width: '250px',
        [theme.breakpoints.down('md')]: {
            width: '200px',
        },
    },
    container:{
        minHeight: '100%',
        bgcolor: palette.common.white,
        pt:2,
        pb:2,
    },
    toformAvatar: { m: 1, bgcolor: palette.blue[100] },
    paragraf: {
        fontFamily: 'Nunito Sans,sans-serif',
        fontSize: '.875rem',
        fontWeight: 400,
        lineHeight: '1.5',
        color: '#fff',
        textAlign: 'left',
        opacity: '.5',
        mt: '1.5rem',
        mb: '1rem'
    }, 
    inputs: {
        display: 'block',
        width: '100%',
        height: 'calc(1.5em + 0.75rem + 2px)',
        padding: 0,
        fontSize: '.875rem',
        fontWeight: '400',
        lineHeight: '1.5',
        color: '#4f5467',
        backgroundClip: 'padding-box',
        border: '1px solid #0bb7af',
        borderRadius: '4px',
        transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
        minWidth: '100%',
    },
    TypographyLogin: {
        alignSelf: 'start',
        display: 'block',
        fontSize: '30px',
        fontWeight: 800,
        height: '36px',
        lineHeight: '36px',
        marginBottom: '8px',
        marginTop: '24px',
        textAlign: 'left',
        marginLeft: '22px',
    },
    errorMessage: {
        width: '100%',
        fontSize: '80%',
        color: '#f64e60',
    }

}

export default styles;